.row {
  margin: 0;
}
.left-section {
  background-color: white;
  justify-content: center;
  color: #3e78c5;
  padding: 15px 3px;
  height: 100vh;
  border-right: solid 1px #27528d;
  text-align: center;
  padding-top: 40vh;
}
.right-section {
  background-color: #3e78c5;
  color: #f9f9f9;
  z-index: 2;
  padding-top: 30vh;
}
.emblem {
  width: 50%;
}
.side-nav {
  background-color: #3e78c5;
  color: #ffffff;
  min-height: 100vh;
  padding-top: 15px;
}
.side-nav .text {
  color: white;
  padding: 0.9rem 0 0.9rem 0;
  font-size: 0.7rem;
  font-weight: 500;
}
.side-nav .text:hover {
  text-decoration: none;
  color: black;
}
.current {
  text-decoration: none;
  border: solid 1px white;
}
.current:hover {
  color: white;
}
.side-nav .text i {
}
.side-nav .nav {
  position: sticky;
  top: 76px;
}
.header {
  background-color: #3e78c5;
  color: #ffffff;
  height: 80px;
  padding: 20px;
}
.main {
  padding: 15px;
}
.avatar {
  height: 50px;
  margin-right: 15px;
}
